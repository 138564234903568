import NiceModal from '@ebay/nice-modal-react'
import { useContext, useEffect } from 'react'

import { useAdminMode } from '@/lib/hooks/useAdminMode'

export const useModalData = () => {
  const activeModals = useContext(NiceModal.NiceModalContext)
  const areThereActiveModals = Object.keys(activeModals).length > 0
  const isAdminMode = useAdminMode()

  useEffect(() => {
    if (areThereActiveModals && !isAdminMode) {
      document.body.classList.add('stop-scroll')
    } else {
      document.body.classList.remove('stop-scroll')
    }

    return () => {
      document.body.classList.remove('stop-scroll')
    }
  }, [areThereActiveModals, isAdminMode])

  return areThereActiveModals
}
